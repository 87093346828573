import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  // Customizable Area Start
  Grid,
  IconButton,
  InputLabel,
  FormHelperText,
  Hidden,
  styled
  // Customizable Area End
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start

import { Formik, Form } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { contactusBanner } from "./assets";
import Loader from "../../../components/src/Loader.web";
import CustomTextField from "../../../components/src/CustomTextField";
import { goToPrevPage } from "../../../components/src/Utilities";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import CustomToaster from "../../../components/src/CustomToaster";

const configJSON = require("./config");

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const StyledContactField = styled(PhoneInput)({
  "& .PhoneInputInput:hover .PhoneInputInput:focus": {
    border: "none",
  },

  "& .MuiFormHelperText-root .Mui-error": {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },
  "& .PhoneInputInput": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputCountry": {
    paddingLeft: "25px",
    fontSize: "25px",
  },

  "& .PhoneInputCountryIcon--border": {
    boxShadow: "none !important",
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  validationSchema = Yup.object({
    fullName: Yup.string().required(configJSON.nameErrMsg),
    email: Yup.string()
      .email(configJSON.invalidEmailMsg)
      .required(configJSON.emailErrMsg),
    subject: Yup.string().required(configJSON.subjectErrMsg),
    details: Yup.string().required(configJSON.detailsErrMsg),
    contactNumber: Yup.string().test(
      "is-number-valid",
      configJSON.contactErrMsg,
      (value) => {
        if (!value) {
          return true
        }

        return isValidPhoneNumber(String(value))
      }
    ),
  });


  intialValues = {
    fullName: "",
    email: "",
    subject: "",
    details: "",
    contactNumber: "",
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <ThemeProvider theme={theme}>
          <Container style={webStyles.containerStyle}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item sm={12} md={6}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    style={webStyles.IconBtnStyle}
                    onClick={() => goToPrevPage()}
                  >
                    <ArrowBackIcon htmlColor="#fff" />
                  </IconButton>

                  <Typography style={webStyles.titleStyle}>
                    {configJSON.titleContactUs}
                  </Typography>
                </Box>

                <Typography style={webStyles.subLabelStyle}>
                  {configJSON.contactUsSubLabel}
                </Typography>

                <Formik
                  initialValues={this.intialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={(values) => {
                    this.postContactDetails(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                  }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <CustomTextField
                            name={configJSON.fullName}
                            asterisk
                            type={configJSON.textType}
                            label={configJSON.labelFullName}
                            fullWidth
                            placeholder={configJSON.namePlaceHolder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fullName && !!errors.fullName}
                            helperText={touched.fullName && errors.fullName}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomTextField
                            name={configJSON.emailName}
                            asterisk
                            label={configJSON.emailLabel}
                            type={configJSON.emailType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder={configJSON.emailPlaceHolder}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <InputLabel style={webStyles.contactLabelSyle}>
                            {configJSON.contactNumberInputLabel}
                          </InputLabel>
                          <StyledContactField
                            data-test-id="contact-field"
                            defaultCountry={configJSON.defaultCountry}
                            style={webStyles.PhoneInputFieldStyle}
                            name={configJSON.nameContactNumber}
                            international
                            placeholder={configJSON.numberPlaceHolder}
                            onChange={(value: string) =>
                              setFieldValue(configJSON.nameContactNumber, value)
                            }
                            onBlur={() =>
                              setFieldTouched(
                                configJSON.nameContactNumber,
                                true
                              )
                            }
                            error={
                              touched.contactNumber && !!errors.contactNumber
                            }
                            helperText={
                              touched.contactNumber && errors.contactNumber
                            }
                          />
                          {touched.contactNumber && !!errors.contactNumber && (
                            <FormHelperText
                              error
                              style={webStyles.formHelperStyle}
                            >
                              {errors.contactNumber}
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <CustomTextField
                            name={configJSON.nameSubject}
                            asterisk
                            type={configJSON.textType}
                            label={configJSON.subjectLabel}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder={configJSON.subjectPlaceholder}
                            error={touched.subject && !!errors.subject}
                            helperText={touched.subject && errors.subject}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomTextField
                            name={configJSON.nameDetails}
                            asterisk
                            label={configJSON.detailsLabel}
                            type={configJSON.textType}
                            fullWidth
                            multiline
                            onChange={handleChange}
                            onBlur={handleBlur}
                            minRows={4}
                            placeholder={configJSON.detailsPlaceholder}
                            error={touched.details && !!errors.details}
                            helperText={touched.details && errors.details}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>
                          <Button
                            type={configJSON.submitType}
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                              ...webStyles.btnSubmitStyle,
                              textTransform: "capitalize",
                            }}
                            data-test-id="btn-submit"
                          >
                            {configJSON.submitButtonText}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>

              <Hidden smDown>
                <Grid item sm={12} md={6}>
                  <Box style={{ textAlign: "end" }}>
                    <img
                      style={{ height: "100%" }}
                      src={contactusBanner}
                      alt="no-img"
                    />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>

            <CustomToaster />

            <Loader isLoading={this.state.isLoading} />
          </Container>
        </ThemeProvider>
      </>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start


const webStyles : { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  formHelperStyle: {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },

  subLabelStyle: {
    color: "#64748B",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    margin: "25px 0",
  },

  contactLabelSyle: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },

  PhoneInputFieldStyle: {
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    border: "1px solid #CBD5E1",
  },

  btnSubmitStyle: {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    borderRadius: "8px",
    height: "56px",
  },
};


// Customizable Area End
