export const landingBanner = require("../assets/landing_banner.png");
export const introImg = require("../assets/intro_image.png");
export const heartfeltImg = require("../assets/heartfelt_banner.png");
export const legacyImage = require("../assets/legacy_image.png");
export const archival = require("../assets/archival.png");
export const community = require("../assets/community.png");
export const jewelry = require("../assets/jewellery.png");
export const fbIcon = require("../assets/fb_icon.png");
export const instagram = require("../assets/instagram.png");
export const linkedIn = require("../assets/linkedln.png");
export const youtube = require("../assets/youtube.png");
export const twitter_icon = require("../assets/twitter_icon.png");