import React from "react";
// Customizable Area Start
import { Box, Container, Avatar, IconButton, InputAdornment, TextField, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import CustomToaster from "../../../components/src/CustomToaster";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { goToPrevPage } from "../../../components/src/Utilities";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { defaultAvatar } from "../../../blocks/user-profile-basic/src/assets";
import { closeIcon, searchIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton";
import CustomSkeleton from "../../../components/src/CustomSkeleton";

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const StyledInputField = styled(TextField)({
  width: "542px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    padding: "10px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input": {
    padding: "10px 8px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: "0 !important",
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "0 !important",
  },
  "@media (max-width: 600px)" : {
    width: "400px",
  },
  "@media (max-width: 425px)" : {
    width: "325px",
  }
});


const StyledBox = styled(Box)({
  "&.love-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "25px",
    "@media (max-width: 600px)": {
      marginTop: "40px",
    },
  },

  "&.content-wrapper" : {
    marginTop: "40px",
    width: "542px",
    "@media (max-width: 600px)" : {
      width: "400px",
    },
    "@media (max-width: 425px)" : {
      width: "325px",
    }

  }

});


const StyledTypography = styled(Typography)({

  "&.handle-text": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 600,
    marginLeft: "15px",
  },

});



const StyledAvatar = styled(Avatar)({
  "&.love-avatar": {
    height: "56px",
    width: "56px",
    border: "2px solid #F3E1DC",
    "@media (max-width: 450px)": {
      height: "40px",
    width: "40px",
    },
  },

});

const StyledSkeleton = styled(CustomSkeleton)({
  "&.avatar-skeleton": {
    height: "56px",
    width: "56px",
    "@media (max-width: 450px)": {
      height: "40px",
      width: "40px",
    },
  },

  "&.handle-skeleton": {
    height: "24px",
    width: "200px",
    marginLeft: "15px",
    borderRadius: "5px",
    "@media (max-width: 450px)": {
      height: "25px",
      width: "150px",
    },
  },

  "&.btn-skeleton": {
    width: "180px",
    height: "40px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    marginRight: "15px",
    "@media (max-width: 600px)": {
      width: "125px",
      height: "35px",
      marginLeft: "10px"
    },
    "@media (max-width: 450px)": {
      width: "80px",
      height: "35px",
    },
  }

});


const StyledCustomButton = styled(CustomButton)({
  "&.btn-follow": {
    width: "155px",
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    "@media (max-width: 450px)": {
      width: "100px",
    height: "35px",
    fontSize: "14px",
    },
  },
})



// Customizable Area End

import Likeapost2Controller, {
  Props,
  configJSON,
} from "./LovesController";



export default class Loves extends Likeapost2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id="profile-container">
        <NavigationMenu navigation={this.props.navigation} id={this.props.id} />
        <Container style={webStyles.containerStyle}>
          <Box display="flex" alignItems="center">
            <IconButton
              data-test-id="back-btn"
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.loves}
            </Typography>
          </Box>
          <ThemeProvider theme={theme}>
            <StyledBox className="love-container">
              <StyledInputField
                data-test-id="input-field"
                variant={configJSON.outlined}
                name={configJSON.searchName}
                type={configJSON.textType}
                value={this.state.searchTerm}
                fullWidth
                placeholder={configJSON.searchPlaceHolder}
                onChange={(event) => {
                  this.handleInputChange(event);
                  this.debounceInputChange();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} style={webStyles.fieldIconStyle} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {Boolean(this.state.searchTerm) && (
                        <img
                          src={closeIcon}
                          style={webStyles.fieldIconStyle}
                          onClick={this.onCrossClick}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <StyledBox className="content-wrapper">
                {this.state.isLoading &&
                  configJSON.skeletonArr.map(() => {
                    return (
                      <Box style={webStyles.contentContainer}>
                        <Box style={webStyles.leftWrapper}>
                          <StyledSkeleton
                            variant={"circle"}
                            className="avatar-skeleton"
                          />
                          <StyledSkeleton
                            variant={"rect"}
                            className="handle-skeleton"
                          />
                        </Box>
                        <Box>
                          <StyledSkeleton
                            variant="rect"
                            className="btn-skeleton"
                          />
                        </Box>
                      </Box>
                    );
                  })}
                {this.state.lovesData.length > 0 ? (
                  this.state.lovesData.map((user) => {
                    return (
                      <Box style={webStyles.contentContainer}>
                        <Box
                          style={webStyles.leftWrapper}
                          data-test-id="user-wrapper"
                          onClick={() =>
                            this.handleProfileClick(Number(user.id))
                          }
                        >
                          <StyledAvatar
                            className="love-avatar"
                            alt="profile"
                            src={
                              user?.attributes?.profile_image ?? defaultAvatar
                            }
                          />
                          <StyledTypography className="handle-text">
                            {user?.attributes?.email?.split("@")[0]}
                          </StyledTypography>
                        </Box>
                        <Box>
                          <StyledCustomButton
                            variant="contained"
                            className="btn-follow"
                            color="primary"
                            fullWidth
                            dataTestId="btn-edit"
                            btnText={"Follow"}
                            onClick={() => null}
                          />
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography style={webStyles.infoStyle}>
                    {configJSON.noUsersFound}
                  </Typography>
                )}
                {this.state.lovesData.length < this.state.totalCount && (
                  <Typography
                   data-test-id="viewmore"
                    style={{
                      ...webStyles.infoStyle,
                      textDecoration: "underline",
                    }}
                    onClick={this.handleViewMoreClick}
                  >
                    {configJSON.viewAll}
                  </Typography>
                )}
              </StyledBox>
            </StyledBox>
          </ThemeProvider>
        </Container>
        <CustomToaster />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },

  leftWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  fieldIconStyle: {
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  infoStyle: {
    fontSize: "18px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    color: "#0F172A",
  },
};

// Customizable Area End
